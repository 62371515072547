<script>
import Layout from "../../layouts/course";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      'My subscriptions': 'Minhas Assinaturas',
      'My invoices': 'Minhas Faturas',
      'Days': 'Dias',
      'Sign up': 'Inscrever-se',
      'You already have an active subscription.': 'Você já tem uma assinatura ativa.',

       months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],

      'My Orders': 'Meus Pedidos',

      'Order': 'Pedido',
      'Date': 'Data',
      'Total': 'Total',
      'Status': 'Status',
      'Payment': 'Pagamento',
      'Details': 'Detalhes',
      'Pending': 'Pendente',
      'Approved': 'Aprovado',
      'Canceled': 'Cancelado',
      'View': 'Ver',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'My subscriptions': 'Minhas Assinaturas',
      'My invoices': 'Mis Facturas',
      'Days': 'Dias',
      'Sign up': 'Regístrate',
      'You already have an active subscription.': 'Ya tienes una suscripción activa.',

      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],

      'My Orders': 'Meus Pedidos',

      'Order': 'Pedido',
      'Date': 'Data',
      'Total': 'Total',
      'Status': 'Estado',
      'Payment': 'Pago',
      'Details': 'Detalles',
      'Pending': 'Pendiente',
      'Approved': 'Aprobado',
      'Canceled': 'Cancelado',
      'View': 'Ver',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },

  components: { Layout, VclList },

  data() {
    return {
      alert: {
        available: { type: '', message: '' }
      },
      actives: '',
      available: {
        loading: true,
        errored: false,
        empty: false,
        list: null,
      },
      modal: {
        status: false,
        id: '',
        days: '',
        name: '',
        value: '',
      },
      table: {
        heade: [
          'Order', 'Date', 'Total', 'Payment', 'Status', 'Details'
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getOrders() {
      this.table.body = null
      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('store/orders')
        .then(response => {
          if (response.data.status=='success') {
            this.table.body = response.data.list
          } else {
            this.table.body = null
          }
        })
        .catch(error => {
          this.table.errored = error
        })
        .finally(() => {
          this.table.loading = false
          if (this.table.body=='' || this.table.body==null) {
            this.table.empty = true
          }
        })
    },
    getAvailable() {
      this.available.loading = true

      api
        .get('subscriptions/available')
        .then((response) => {
          if (response.data.status === 'success') {
            this.available.list = response.data.list
            this.available.loading = false
          } else {
            this.available.list = null
          }
        })
        .catch(error => {
          this.available.errored = error
          this.available.loading = false
        })
        .finally(() => {
          this.available.loading = false
        })
    },
    getActive() {
      api
        .get('subscriptions/active')
        .then((response) => {
          if (response.data.status === 'success') {
            this.actives = response.data.list
          }
        })
    },
    setChoose(idSub) {
      api
        .post('subscriptions/choose', {
          id: idSub
        })
        .then((response) => {
          if (response.data.status == 'success') {
            this.modal.status = false
            this.$router.push('/store/order/'+response.data.order.id);
          } else {
            this.alert.available.type = 'bg-warning'
            this.alert.available.message = response.data.message
          }
        })
    },
    getChoose(available) {
      this.modal.status = true,
      this.modal.id = available.id,
      this.modal.days = available.days,
      this.modal.name = available.name,
      this.modal.value = available.value
    }
  },

  mounted() {
    this.getActive()
    this.getAvailable()
    this.getOrders()
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between">
      <h4 class="mb-0 font-size-18">{{ t('My subscriptions') }}</h4>
    </div>

    <div v-if="actives.length == 0">
      <div class="alert alert-warning">
        Para ter acesso a todos os cursos você precisa ter uma assinatura ativa.
      </div>
      <div class="card text-white-50 bg-soft-danger">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <i class="mdi mdi-check-all mr-3 font-size-24 text-white"></i>
            <h5 class="mb-0 text-white flex-fill">Inativo<br><small class="font-size-12">desde 00/00/0000</small></h5>
            <h5 class="mb-0 text-white">0 dias<br><small class="font-size-12">ativo por mais</small></h5>
          </div>
        </div>
      </div>
    </div>
    <div v-else v-for="(active, index) in actives" :key="index" class="card text-white-50 bg-success">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
          <i class="mdi mdi-check-all mr-3 font-size-24 text-white"></i>
          <h5 class="mb-0 text-white flex-fill">{{ active.name }}<br><small class="font-size-12">desde {{ active.date.begin }}</small></h5>
          <h5 class="mb-0 text-white">{{ active.days_to_end }} dias<br><small class="font-size-12">ativo por mais</small></h5>
        </div>
      </div>
    </div>

    <div v-if="!available.loading" class="row">
      <div v-for="(available, index) in available.list" :key="index" class="col-xl-3 col-md-6">
        <div class="card plan-box">
          <div class="card-body text-center p-4">
            <div class="media">
              <div class="media-body">
                <h5>{{ available.name }}</h5>
                <p class="mb-0 text-muted">{{ available.days }} dias de acesso ilimitado a todos os cursos</p>
              </div>
            </div>
            <div class="py-2">
              <h2>{{ available.value }} <span class="font-size-13"></span></h2>
            </div>
            <div class="text-center">
              <a @click="getChoose(available)" :class="{'disabled': actives}" class="btn btn-danger position-relative"> {{ t('Sign up') }} </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-title-box d-flex justify-content-between">
      <h4 class="mb-0 font-size-18">{{ t('My invoices') }}</h4>
    </div>

    <div class="card">
      <div class="card-body">
        <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
        <div v-else-if="table.empty">{{ t('No records found.') }}</div>
        <div v-else class="table-responsive">
          <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
          <table v-else class="table table-centered table-nowrap">
            <thead class="thead-light">
              <tr>
                <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td,index) in table.body" :key="index">
                <td v-for="(data,row) in td" :key="row">
                  <div v-if="row === 'id'">
                    <strong>#{{ data }}</strong>
                  </div>
                  <div v-else-if="row === 'status'">
                    <span v-if="data === '0'" class="badge badge-soft-warning font-size-12">{{ t('Pending') }}</span>
                    <span v-else-if="data === '1'" class="badge badge-soft-success font-size-12">{{ t('Approved') }}</span>
                    <span v-else-if="data === '2'" class="badge badge-soft-danger font-size-12">{{ t('Canceled') }}</span>
                  </div>
                  <div v-else class="notranslate">
                    {{ data }}
                  </div>
                </td>
                <td>
                  <router-link :to="'/store/order/'+td.id" class="btn btn-danger btn-sm btn-rounded">
                    {{ t('View') }}
                    <i class="mdi mdi-arrow-right ml-1"></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <b-modal v-model="modal.status" v-bind:hide-footer="true" centered>
      <div v-if="alert.available.message" :class="'alert ' + alert.available.type">{{ t(alert.available.message) }}</div>
      <div class="text-center">
        <h5>{{ modal.name }}</h5>
        <p class="mb-0 text-muted">{{ modal.days }} dias de acesso ilimitado a todos os cursos</p>
        <h2>{{ modal.value }} <span class="font-size-13"></span></h2>
      </div>
      <div class="mt-3 text-center">
        Deseja se inscrever neste plano?<br>
        <a @click="setChoose(modal.id)" class="btn btn-success mr-2">Sim</a>
        <a @click="modal.status = false" class="btn btn-danger">Não</a>
      </div>
    </b-modal>
  </Layout>
</template>